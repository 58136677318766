<template>
  <b-img
    fluid
    :src="appLogoImage"
    alt="Login V2"
    class="logoComplete"
  />
</template>
<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app

    return {
      appLogoImage,
    }
  },
}
</script>
<style scoped>
.logoComplete {
  width: 102px !important;
  height: 50px !important;
}
</style>
